<template>
  <div>
    <!-- 应用软件start -->
    <div class="application d-none d-sm-block">
      <div class="_tit">应用软件</div>
      <div class="_tit2">Application software</div>
      <div class="_lin"></div>
      <div class="main application_main">
        <div class="accordion" id="accordionExample">
          <!-- 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <span class="app_tit"
                  ><img src="@/assets/image/cc (1).png" alt="" />
                  NAS网络存储</span
                >
                <span class="app_p"
                  >NAS网络存储相关软件:
                  包括固件、android客户端、iOS客户端、Web客户端</span
                >
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (3).png" alt="" />
                  </div>
                  <div class="app_min_tit">RealTek的方案NAS固件</div>
                  <div class="app_min_p">使用RealTek为CPU的固件软件版本</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (1).png" alt="" />
                  </div>
                  <div class="app_min_tit">Android 客户端</div>
                  <div class="app_min_p">
                    使用NAS的Android客户端软件,可以远程控制
                  </div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (2).png" alt="" />
                  </div>
                  <div class="app_min_tit">iOS 客户端</div>
                  <div class="app_min_p">
                    使用NAS的iOS客户端软件,可以远程控制
                  </div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (4).png" alt="" />
                  </div>
                  <div class="app_min_tit">Web客户端</div>
                  <div class="app_min_p">
                    使用NAS的WEB客户端软件,可以远程控制
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <span class="app_tit"
                  ><img src="@/assets/image/cc (2).png" alt="" /> 手机存储</span
                >
                <span class="app_p"
                  >手机存储相关软件:包括硬件、固件、android客户端、iOS客户端、Windows客户端、Mac客户端、Linux客户端</span
                >
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (1).png" alt="" />
                  </div>
                  <div class="app_min_tit">Android 客户端</div>
                  <div class="app_min_p">在Android手机上运行客户端软件</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (2).png" alt="" />
                  </div>
                  <div class="app_min_tit">iOS 客户端</div>
                  <div class="app_min_p">在iPhone/iPad手机上运行客户端软件</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (3).png" alt="" />
                  </div>
                  <div class="app_min_tit">Windows客户端</div>
                  <div class="app_min_p">在Windows系统上运行的客户端软件</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (4).png" alt="" />
                  </div>
                  <div class="app_min_tit">Mac客户端</div>
                  <div class="app_min_p">在mac系统上运行的客户端软件</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <span class="app_tit"
                  ><img src="@/assets/image/cc (3).png" alt="" />
                  手机连线摄像头</span
                >
                <span class="app_p"
                  >手机连线摄像头软件:包括固件、APP客户端</span
                >
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (3).png" alt="" />
                  </div>
                  <div class="app_min_tit">手机连线摄像头固件</div>
                  <div class="app_min_p">手机连线摄像头的固件版本</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (1).png" alt="" />
                  </div>
                  <div>Android 客户端</div>
                  <div>使用NAS的Android客户端软件,可以远程控制</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (2).png" alt="" />
                  </div>
                  <div>iOS 客户端</div>
                  <div>使用NAS的iOS客户端软件,可以远程控制</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <span class="app_tit"
                  ><img src="@/assets/image/cc (4).png" alt="" /> 智能家居</span
                >
                <span class="app_p">
                  智能家居:包括硬件、固件、APP客户端、web客户端</span
                >
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (4).png" alt="" />
                  </div>
                  <div class="app_min_tit">智能家居硬件、固件</div>
                  <div class="app_min_p">手机连线摄像头的固件版本</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (1).png" alt="" />
                  </div>
                  <div class="app_min_tit">Android 客户端</div>
                  <div class="app_min_p">
                    使用NAS的Android客户端软件,可以远程控制
                  </div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (2).png" alt="" />
                  </div>
                  <div class="app_min_tit">iOS 客户端</div>
                  <div class="app_min_p">
                    使用NAS的iOS客户端软件,可以远程控制
                  </div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (3).png" alt="" />
                  </div>
                  <div class="app_min_tit">Web客户端</div>
                  <div class="app_min_p">
                    使用NAS的WEB客户端软件,可以远程控制
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <span class="app_tit"
                  ><img src="@/assets/image/cc (5).png" alt="" /> AI周边</span
                >
                <span class="app_p">AI周边:包括固件、APP客户端</span>
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (4).png" alt="" />
                  </div>
                  <div class="app_min_tit">AI周边固件</div>
                  <div class="app_min_p">手机连线摄像头的固件版本</div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (1).png" alt="" />
                  </div>
                  <div class="app_min_tit">Android 客户端</div>
                  <div class="app_min_p">
                    使用NAS的Android客户端软件,可以远程控制
                  </div>
                </div>
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (2).png" alt="" />
                  </div>
                  <div class="app_min_tit">iOS 客户端</div>
                  <div class="app_min_p">
                    使用NAS的iOS客户端软件,可以远程控制
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <span class="app_tit"
                  ><img src="@/assets/image/cc (6).png" alt="" />
                  存储拓展器</span
                >
                <span class="app_p"> 存储拓展器：包括固件</span>
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body aaa">
                <div class="application_Box">
                  <div>
                    <img src="@/assets/image/yy (1).png" alt="" />
                  </div>
                  <div class="app_min_tit">存储拓展器固件</div>
                  <div class="app_min_p">手机连线摄像头的固件版本</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="application d-sm-none">
      <div class="_tit">应用软件</div>
      <div class="_tit2">Application software</div>
      <div class="_lin"></div>
      <div class="main application_main">
        <div class="accordion" id="accordionExample">
          <!-- 1 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div class="app_tit">
                  <img src="@/assets/image/cc (1).png" alt="" />
                </div>
                <div>NAS网络存储</div>
                <div class="app_p">
                  NAS网络存储相关软件:
                  包括固件、android客户端、iOS客户端、Web客户端
                </div>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (3).png" alt="" />
                    </div>
                    <div class="app_min_tit">RealTek的方案NAS固件</div>
                    <div class="app_min_p">使用RealTek为CPU的固件软件版本</div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (1).png" alt="" />
                    </div>
                    <div class="app_min_tit">Android 客户端</div>
                    <div class="app_min_p">
                      使用NAS的Android客户端软件,可以远程控制
                    </div>
                  </div>
                </div>
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (2).png" alt="" />
                    </div>
                    <div class="app_min_tit">iOS 客户端</div>
                    <div class="app_min_p">
                      使用NAS的iOS客户端软件,可以远程控制
                    </div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (4).png" alt="" />
                    </div>
                    <div class="app_min_tit">Web客户端</div>
                    <div class="app_min_p">
                      使用NAS的WEB客户端软件,可以远程控制
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <div class="app_tit">
                  <img src="@/assets/image/cc (2).png" alt="" />
                </div>
                <div>手机存储</div>
                <div class="app_p">
                  手机存储相关软件:包括硬件、固件、android客户端、iOS客户端、Windows客户端、Mac客户端、Linux客户端
                </div>
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (1).png" alt="" />
                    </div>
                    <div class="app_min_tit">Android 客户端</div>
                    <div class="app_min_p">在Android手机上运行客户端软件</div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (2).png" alt="" />
                    </div>
                    <div class="app_min_tit">iOS 客户端</div>
                    <div class="app_min_p">
                      在iPhone/iPad手机上运行客户端软件
                    </div>
                  </div>
                </div>
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (3).png" alt="" />
                    </div>
                    <div class="app_min_tit">Windows客户端</div>
                    <div class="app_min_p">在Windows系统上运行的客户端软件</div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (4).png" alt="" />
                    </div>
                    <div class="app_min_tit">Mac客户端</div>
                    <div class="app_min_p">在mac系统上运行的客户端软件</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 3 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <div class="app_tit">
                  <img src="@/assets/image/cc (3).png" alt="" />
                </div>
                <div>手机连线摄像头</div>
                <div class="app_p">手机连线摄像头软件:包括固件、APP客户端</div>
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (3).png" alt="" />
                    </div>
                    <div class="app_min_tit">手机连线摄像头固件</div>
                    <div class="app_min_p">手机连线摄像头的固件版本</div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (1).png" alt="" />
                    </div>
                    <div>Android 客户端</div>
                    <div>使用NAS的Android客户端软件,可以远程控制</div>
                  </div>
                </div>
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (2).png" alt="" />
                    </div>
                    <div>iOS 客户端</div>
                    <div>使用NAS的iOS客户端软件,可以远程控制</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 4 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <div class="app_tit">
                  <img src="@/assets/image/cc (4).png" alt="" />
                </div>
                <div>智能家居</div>
                <div class="app_p">
                  智能家居:包括硬件、固件、APP客户端、web客户端
                </div>
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (4).png" alt="" />
                    </div>
                    <div class="app_min_tit">智能家居硬件、固件</div>
                    <div class="app_min_p">手机连线摄像头的固件版本</div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (1).png" alt="" />
                    </div>
                    <div class="app_min_tit">Android 客户端</div>
                    <div class="app_min_p">
                      使用NAS的Android客户端软件,可以远程控制
                    </div>
                  </div>
                </div>
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (2).png" alt="" />
                    </div>
                    <div class="app_min_tit">iOS 客户端</div>
                    <div class="app_min_p">
                      使用NAS的iOS客户端软件,可以远程控制
                    </div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (3).png" alt="" />
                    </div>
                    <div class="app_min_tit">Web客户端</div>
                    <div class="app_min_p">
                      使用NAS的WEB客户端软件,可以远程控制
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 5 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <div class="app_tit">
                  <img src="@/assets/image/cc (5).png" alt="" />
                </div>
                <div>AI周边</div>
                <div class="app_p">AI周边:包括固件、APP客户端</div>
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (4).png" alt="" />
                    </div>
                    <div class="app_min_tit">AI周边固件</div>
                    <div class="app_min_p">手机连线摄像头的固件版本</div>
                  </div>
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (1).png" alt="" />
                    </div>
                    <div class="app_min_tit">Android 客户端</div>
                    <div class="app_min_p">
                      使用NAS的Android客户端软件,可以远程控制
                    </div>
                  </div>
                </div>
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (2).png" alt="" />
                    </div>
                    <div class="app_min_tit">iOS 客户端</div>
                    <div class="app_min_p">
                      使用NAS的iOS客户端软件,可以远程控制
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 6 -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <div class="app_tit">
                  <img src="@/assets/image/cc (6).png" alt="" />
                </div>
                <div>存储拓展器</div>
                <div class="app_p">存储拓展器：包括固件</div>
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body aaa">
                <div class="add_accordion">
                  <div class="application_Box">
                    <div>
                      <img src="@/assets/image/yy (1).png" alt="" />
                    </div>
                    <div class="app_min_tit">存储拓展器固件</div>
                    <div class="app_min_p">手机连线摄像头的固件版本</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用软件end -->
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeName: "index1"
    }
  }
}
</script>

<style lang="scss" scoped>
// 标题
._tit {
  margin-bottom: 4px;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Regular;
  color: #333333;
  font-weight: 400;
}
._tit2 {
  text-align: center;
  font-family: SFPro-Bold;
  font-size: 24px;
  color: #dddddd;
  font-weight: 700;
  line-height: 30px;
}
._lin {
  width: 80px;
  border-bottom: 4px solid #4066f5;
  display: flex;
  justify-content: center;
  margin: 24px auto;
}
// 应用软件start
.application {
  padding-top: 60px;
  padding-bottom: 60px;
}
/deep/.accordion-button {
  background-color: #f5f7fe;
}
/deep/.accordion-button:focus {
  border-color: #962261 !important;
  box-shadow: rgba(0, 0, 0, 0);
}
/deep/.accordion-item {
  border: none;
}
/deep/.accordion-button {
  border: none;
  margin-bottom: 10px;
}
/deep/.accordion-button:not(.collapsed) {
  box-shadow: none;
}
.application_Box {
  width: 30%;
  text-align: center;
}
.accordion-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}
.aaa {
  justify-content: center;
}
.app_tit {
  font-size: 16px;
  font-family: PingFangSC-Semibold;
  color: #040849;
  margin-right: 20px;
}
.app_p {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #596580;
}
.app_min_tit {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  color: #040849;
}
.app_min_p {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #596580;
}
// 应用软件end
// 手机
@media only screen and (max-width: 760px) {
  // 标题
  ._tit {
    font-size: 20px;
  }
  ._tit2 {
    font-size: 14px;
  }
  ._lin {
    border-bottom: 2px solid #4066f5;
  }
  // 应用软件
  .application_main {
    width: calc(100vw);
  }
  .accordion-button {
    display: block;
    text-align: center;
  }
  .app_tit {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .accordion-body {
    display: block;
  }
  .add_accordion {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
}
</style>
